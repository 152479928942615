export const actionTypes = {
    SIGNUP_REQUEST: 'SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_FAILURE: 'SIGNUP_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    LOGOUT: 'LOGOUT',

    PROFILEINFO_REQUEST: 'PROFILEINFO_REQUEST',
    PROFILEINFO_SUCCESS: 'PROFILEINFO_SUCCESS',
    PROFILEINFO_FAILURE: 'PROFILEINFO_FAILURE',
};